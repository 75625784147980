/**
 * 获取制定日期的星期几 汉字文本
 * @param {Date} date
 * @returns {string}
 */
function getWeekText(date) {
    return ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][
        date.getDay()
        ];
}

export {getWeekText};
