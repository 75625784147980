let $g = {};
let id = 10000;
const Global = {
    /**
     * 获取全局变量
     * @param {string} name
     */
    get(name) {
        return $g[name];
    },
    /**
     * 设置全局变量
     * @param {String}name
     * @param value
     */
    set(name, value) {
        $g[name] = value;
    },
    /**
     * 移除全局变量
     * @param {string} name
     */
    remove(name) {
        delete $g[name];
    },

    newId() {
        id++;
        return id;
    },
};

export {
    Global
}

export default Global;
