import common from "../common";
import DateTool from "./index";

const Config = {
    valueErrorNotice: "value必须是数字",
    weekDayText: [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
    ]
};

/**
 * ${Date} $date
 */
class kDate {
    /**
     *
     * @param {Date|string|number} para0 - 年，日期，完整日期，时间戳（秒或者毫秒）
     * @param {number} month
     * @param {number} day
     * @param {number} hour
     * @param {number} minute
     * @param {number} second
     */
    constructor(
        para0,
        month,
        day,
        hour,
        minute,
        second
    ) {
        const type = common.getType(para0);
        switch (type) {
            case "Date":
                this.$date = para0;
                break;
            case "String":
                this.$date = DateTool.parse(para0);
                break;
            case "Number":
                if (para0 < 10000) {
                    this.$date = new Date(
                        para0,
                        month || 1 - 1,
                        day || 1,
                        hour || 0,
                        minute || 0,
                        second || 0
                    );
                } else if (para0 < 1000000000000) {//时间戳 秒
                    this.$date = new Date(para0 * 1000);
                } else {
                    this.$date = new Date(para0);//时间戳 毫秒
                }
                break;
            default:
                this.$date = new Date();
                break;
        }
        return this;
    }

    /**
     * 获取年份
     * @returns {number}
     */
    get year() {
        return this.$date.getFullYear();
    }

    /**
     * 获取月份 1-12
     * @returns {number}
     */
    get month() {
        return this.$date.getMonth() + 1;
    }

    /**
     * 获取日期 1-31
     * @returns {number}
     */
    get day() {
        return this.$date.getDate();
    }

    /**
     * 获取星期 1-7
     * @returns {number}
     */
    get weekDay() {
        return this.$date.getDay() || 7;
    }

    /**
     * 获取星期几（中午）
     * @returns {string}
     */
    get weekDayCn() {
        return Config.weekDayText[this.$date.getDay()];
    }

    /**
     * 小时
     * @returns {number}
     */
    get hour() {
        return this.$date.getHours();
    }

    /**
     * 分钟
     * @returns {number}
     */
    get minute() {
        return this.$date.getMinutes();
    }

    /**
     * 秒
     * @returns {number}
     */
    get second() {
        return this.$date.getSeconds();
    }

    /**
     * 毫秒
     * @returns {*}
     */
    get milliSeconds() {
        return this.$date.getMilliseconds();
    }

    timeStamp(long = false) {
        return long
            ? this.$date.valueOf()
            : parseInt(String(this.$date.valueOf() / 1000));
    }

    get dateStr() {
        return this.toString("yyyy-MM-dd");
    }

    /**
     * 输出中文日期格式
     * @returns {string}
     */
    get dateStrCn() {
        return DateTool.format(this.$date, "yyyy年MM月dd日");
    }

    /**
     * 输出时间
     * @returns {string}
     */
    get timeStr() {
        return DateTool.format(this.$date, "hh:mm:ss");
    }

    /**
     * 输出时间 不包含秒
     * @returns {string}
     */
    get shortTimeStr() {
        return DateTool.format(this.$date, "hh:mm");
    }

    toString(fmt = "yyyy-MM-dd hh:mm:ss") {
        const values = {
            "M+": this.month, //月份
            "d+": this.day, //日
            "h+": this.hour, //小时
            "m+": this.minute, //分
            "s+": this.second //秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (this.year + "").substr(4 - RegExp.$1.length)
            );
        }
        Object.keys(values).forEach(function (key) {
            if (new RegExp("(" + key + ")").test(fmt)) {
                // @ts-ignore
                const value = values[key];
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? value : ("00" + value).substr(-2)
                );
            }
        });
        return fmt;
    }

    /**
     * 增加天数
     * @param {number} value
     * @returns {kDate}
     */
    addDays(value) {
        this.$date.setDate(this.$date.getDate() + value);
        return this;
    }

    /**
     * 增减月数
     * @param {number} value
     * @returns {kDate}
     */
    addMonths(value) {
        this.$date.setMonth(this.$date.getMonth() + value);
        return this;
    }

    /**
     * 增减年数
     * @param {number} value
     * @returns {kDate}
     */
    addYears(value) {
        this.$date.setFullYear(this.$date.getFullYear() + value);
        return this;
    }

    /**
     * 增减周数
     * @param {number} value
     * @returns {kDate}
     */
    addWeeks(value) {
        return this.addDays(value * 7);
    }

    /**
     * 增减小时数
     * @param {number} value
     * @returns {kDate}
     */
    addHours(value) {
        this.$date.setHours(this.$date.getHours() + value);
        return this;
    }

    /**
     * 增减分钟数
     * @param {number} value
     * @returns {kDate}
     */
    addMinutes(value) {
        this.$date.setMinutes(this.$date.getMinutes() + value);
        return this;
    }

    /**
     * 增减秒数
     * @param {number} value
     * @returns {kDate}
     */
    addSeconds(value) {
        this.$date.setSeconds(this.$date.getSeconds() + value);
        return this;
    }
}

export {kDate};
export default kDate;
