import { parse } from "./parse";

/**
 * 计算时间差，并转化为中文显示
 * @param {Date} end
 * @param {Date} start
 * @returns {string|Number}
 */
function getTimeDiffText(start, end) {
  return timeDiffToStr(getTimeDiffSeconds(start, end));
}

/**
 * 计算时间差
 * @param {Date} start - 开始时间
 * @param {Date} end - 结束时间
 * @returns {number} - 秒
 */
function getTimeDiffSeconds(start, end) {
  return parseInt(String((end.valueOf() - start.valueOf()) / 1000));
}

/**
 * 倒计时场景下显示时间
 * @param {Number} value - 秒
 * @param {boolean} showTime
 * @returns {string}
 */
function timeDiffToStr(value, showTime = true) {
  let front = "";
  if (value < 0) {
    value = -value;
    front = "-";
  }
  const d = Math.floor(value / 60 / 60 / 24);
  const h = Math.floor((value / 60 / 60) % 24);
  const m = Math.floor((value / 60) % 60);
  const s = Math.floor(value % 60);
  let result = "";
  if (d > 0) {
    result += d + "天";
  }
  if (h > 0) {
    result += h + "小时";
  }
  if (showTime) {
    if (m > 0) {
      result += m + "分钟";
    }
    if (s > 0) {
      result += s + "秒";
    }
  }
  return front + result;
}

/**
 * 将秒数转换为对象，主要用于倒计时展示
 * @param {Number} value - 秒
 * @returns {string}
 */
function getTimeDiffObj(value) {
  const d = Math.floor(value / 60 / 60 / 24);
  const h = Math.floor((value / 60 / 60) % 24);
  const m = Math.floor((value / 60) % 60);
  const s = Math.floor(value % 60);
  return {
    day: d,
    hour: h,
    minute: m,
    second: s
  };
}

/**
 * 显示指定时间与当前时间的距离
 * @param {string | Date} time
 * @param {Date} endTime
 * @returns {string}
 */
function getTimeDistance(time, endTime) {
  if (typeof time === "string") {
    time = parse(time);
  }
  const now = (endTime || new Date()).valueOf() / 1000;
  const value = time.valueOf() / 1000;
  const distance = parseInt(String(now - value));
  if (distance > 365 * 24 * 3600) {
    return parseInt(String(distance / (365 * 24 * 3600))) + "年前";
  } else if (distance > 30 * 24 * 3600) {
    return parseInt(String(distance / (30 * 24 * 3600))) + "个月前";
  } else if (distance > 24 * 3600) {
    return parseInt(String(distance / (24 * 3600))) + "天前";
  } else if (distance > 3600) {
    return parseInt(String(distance / 3600)) + "小时前";
  } else if (distance > 60) {
    return parseInt(String(distance / 60)) + "分钟前";
  } else {
    return "刚刚";
  }
}

export {
  getTimeDiffText,
  getTimeDiffSeconds,
  timeDiffToStr,
  getTimeDistance,
  getTimeDiffObj
};
