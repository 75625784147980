import { kDate } from "./kDate";

/**
 * 获取指定年月的最大天数
 * @param {number} year - 年
 * @param {number} month - 月
 * @returns {number}
 */
const getMaxDayOfMonth = function(year , month ) {
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    case 2:
      return new kDate(year, 3, 1).addDays(-1).day;
  }
};

export { getMaxDayOfMonth };
