let serverPath = "//kx.1314179.com.cn";
let openServer = '//jd.feihe.site'

if (process.env.NODE_ENV === "development") {
    serverPath = "//192.168.3.99";
    //openServer = serverPath;
}

if (process.env.NODE_ENV === 'production') {
    serverPath = "";
}
const AppConfig = {
    apiUrl(url) {
        return serverPath + url;
    },
    openServer,
    debug: false,
    jdApi: serverPath + "/api/jd.ashx",
    SITE_CODE: "client",
    imgServer: openServer,
    apiMode: "action",

};

export {AppConfig};

export default AppConfig;
