const common = {
    /**
     *判断对象类型
     * @param obj
     * @returns {string} - Null、Undefined、String、Number、Boolean、Function、Date、Array、Object
     */
    getType(obj) {
        let type = Object.prototype.toString
            .call(obj)
            .match(/^\[object\s(.*)\]$/);
        return type ? type[1] : "";
    },
    /**
     * 有选择性的复制部分属性
     * @param target - 目标对象
     * @param source - 源对象
     * @param keys - 需要复制的字段
     */
    assign(target, source, keys) {
        if (
            this.getType(target) === "Object" &&
            this.getType(source) === "Object"
        ) {
            this.getArray(keys).forEach(key => {
                target[key] = source[key];
            });
            return target;
        } else {
            throw "common.assign:参数类型不符";
        }
    },

    /**
     * 读取地址栏参数
     */
    getPara() {
        let queryStr = self.location.href.split("?"),
            GETs,
            result = {},
            item;
        if (queryStr[1]) {
            //过滤掉#及其后面的部分
            GETs = queryStr[1]
                .toString()
                .split("#")[0]
                .split("&");
            for (let i = 0; i < GETs.length; i++) {
                item = GETs[i].split("=");
                result[item[0]] = item[1];
                result[item[0].toLowerCase()] = item[1];
            }
        }
        return result;
    },
    /**
     * 从文件名提取文件后缀名
     * @param fileName
     * @returns {string}
     */
    getFileExt(fileName) {
        if (!fileName) {
            return "";
        }
        let temp = fileName.split(".");
        return temp[temp.length - 1].toLowerCase();
    },
    /**
     * 根据文件后缀名获取文件格式
     * @param ext
     * @returns {string}
     */
    getFileType(ext) {
        switch (ext) {
            case "jpg":
            case "jfif":
            case "jpeg":
            case "gif":
            case "png":
            case "bmp":
                return "img";
            case "doc":
            case "docx":
                return "word";
            case "xls":
            case "xlsx":
            case "csv":
                return "excel";
            case "pdf":
                return "pdf";
            case "ppt":
            case "pptx":
                return "ppt";
            case "txt":
                return "text";
            default:
                return "other";
        }
    },
    getArray(obj) {
        if (Array.isArray(obj)) {
            return obj;
        }
        if (typeof obj === "string") {
            return obj.split(",");
        }
        return [];
    },
    /**
     * 设置标题
     * @param title
     */
    setTitle(title) {
        document.title = title;
    },
    /**
     * @param {number|string} value
     */
    toInt(value) {
        switch (typeof value) {
            case "string":
                return parseInt(value);
            case "number":
                return parseInt(value.toString());
        }
    },
    /**
     * @param {number|string} value
     */
    toFloat(value) {
        switch (typeof value) {
            case "string":
                return parseFloat(value);
            case "number":
                return parseFloat(value.toString());
        }
    },
    /**
     * @param {number} min
     * @param {number} max
     */
    getRnd(min, max) {
        const Range = max - min;
        const Rand = Math.random();
        return (min + Math.round(Rand * Range));
    },
    /**
     * @param {string} value
     * @param {boolean} fixed
     */
    add1pint(value, fixed = true) {
        return fixed ? (parseFloat(value) + 1).toFixed(1) : (parseFloat(value) + 1).toString();
    }
};

export default common;

export {common};
