import common from "../common";

/**
 * 将net输出的日期字符串转为日期格式
 * @param {string} str
 * @returns {Date}
 */
function strToDate(str) {
    if (str.length === 0) {
        return new Date();
    }
    //去除.后面的毫秒部分，并将2018/01/01转为2018-01-01
    str = str.split(".")[0].replace(/\//g, "-");

    //将T转为空格
    const dateStr = str.replace("T", " ").split(" ");
    const arrDate = dateStr[0].split("-");
    if (arrDate.length !== 3) {
        throw `dtTool.strToDt：${str}不是有效的日期格式`;
    }
    let arrTime = [0, 0, 0];
    if (dateStr.length > 1) {
        const times = dateStr[1].split(":").map(function (item) {
            return parseInt(item) || 0;
        });
        times.forEach(function (item, index) {
            arrTime[index] = item;
        });
    }
    return new Date(
        parseInt(arrDate[0]),
        parseInt(arrDate[1]) - 1,
        parseInt(arrDate[2]),
        parseInt(String(arrTime[0] || 0)),
        parseInt(String(arrTime[1] || 0)),
        parseInt(String(arrTime[2] || 0))
    );
}

/**
 * 日期/字符串/时间戳转换为时间（传入日期格式则不作处理）
 * 其他类型返回当前时间
 * @param {Date|String|Number|null} val
 * @returns {*}
 */
function parse(val) {
    const type = common.getType(val);
    switch (type) {
        case "Date":
            return new Date(val.valueOf()); //返回新的日期对象，避免对其做的改动影响原对象
        case "String":
            return strToDate(val);
        case "Number":
            return val > 1000000000000
                ? new Date(val)
                : new Date(val * 1000);
        default:
            return new Date();
    }
}

/**
 * 日期格式化
 * @param {Date} date
 * @param {String} fmt
 * @returns {string}
 */
function format(date, fmt = "yyyy-MM-dd hh:mm:ss") {
    const o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds() //秒
        // "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        // "S": this.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    Object.keys(o).forEach(function (key) {
        if (new RegExp("(" + key + ")").test(fmt)) {
            // @ts-ignore
            const value = o[key]
            as
            string;
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? value : ("00" + value).substr(-2)
            );
        }
    });
    return fmt;
}

/**
 * 转换并且格式化
 * @param {String|Date|Number} str
 * @param {String} tpl
 * @returns {string}
 */
function parseAndFormat(str, tpl) {
    const date = parse(str);
    return format(date, tpl);
}

export {strToDate, parse, format, parseAndFormat};
