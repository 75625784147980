import {AppConfig} from "@/app-config.js";

class CacheModel {

    constructor() {
        this.front = AppConfig.SITE_CODE;
    }

    /**
     * 获取缓存
     * @param {string} key - 键名
     * @param { boolean} useFront - 是否启用前缀
     */
    get(key, useFront = true) {
        const name = useFront ? `${this.front}${key}` : key;
        return localStorage.getItem(name);
    }

    /**
     * 保存字符型缓存
     * @param {string} key - 键名
     * @param {string} value - 键值
     * @param { boolean} useFront - 是否启用前缀
     */
    set(key, value, useFront = true) {
        const name = useFront ? `${this.front}${key}` : key;
        localStorage.setItem(name, value);

    }

    /**
     * 保存object型缓存
     * @param {string} key - 键名
     * @param {Object} value - 键值
     * @param { boolean}useFront - 是否启用前缀
     */
    setJson(key, value, useFront = true) {
        const name = useFront ? `${this.front}${key}` : key;
        localStorage.setItem(name, JSON.stringify(value));
    }

    /**
     * 读取object型缓存
     * @param {string} key - 键名键值
     * @param { boolean }useFront - 是否启用前缀
     */
    getJson(key, useFront = true) {
        const name = useFront ? `${this.front}${key}` : key;
        if (this.has(name, false)) {
            return JSON.parse(this.get(name, false) || '{}')
        } else {
            return null;
        }
    }

    /**
     * 判断缓存是否存在
     * @param {string} key - 键名
     * @param useFront - 是否启用前缀
     */
    has(key, useFront = true) {
        const name = useFront ? `${this.front}${key}` : key;
        return localStorage.getItem(name) !== null;
    }

    /**
     * 移除缓存
     * @param {string} key - 键名
     * @param useFront
     */
    remove(key, useFront = true) {
        const name = useFront ? `${this.front}${key}` : key;
        localStorage.removeItem(name);

    }
}

const Cache = new CacheModel();

export default Cache;

export {
    Cache,
    CacheModel
}
