<template>
  <div id="app-menu">
    <app-menu></app-menu>
  </div>
  <div id="app-main">
    <router-view/>
  </div>
</template>

<script setup>
import {ElMessageBox, ElNotification, ElMessage} from "element-plus";
import {layer} from "@/utils";
import AppMenu from "@/components/app-menu/app-menu.vue";

layer.error = function (msg) {
  ElMessageBox.alert(msg, {confirmButtonText: '确定',});
}
layer.confirm = function (msg) {
  return ElMessageBox.confirm(msg, {confirmButtonText: '确定', cancelButtonText: '取消',})
}
layer.success = function (msg) {
  ElMessage.success({message: msg, type: 'success',});
}

</script>
<style lang="less">
@import "style/public-all";
@import "style/app";

#app-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  background-color: #0084f4;
  height: 100vh;
}

#app-main {
  padding: 20px 20px 20px 220px;
  background-color: white;
  min-height: 100vh;
  box-sizing: border-box;
}
</style>
