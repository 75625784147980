<template>
  <div>


    <p>下单状态：<span :class="open?'run':'stop'">{{ open ? '下单中' : '暂不下单' }}</span></p>

    <p class="">主控命令最后更新：<span class="p-date">{{ lastUpdate }}</span></p>

    <el-divider></el-divider>

    <p>当前电脑：<span :class="run?'run':'stop'">{{ run ? '监听中' : '休息中' }}</span></p>

    <p class="" v-if="run">上次询问时间：<span class="p-date">{{ lastQuery }}</span></p>

    <div class="p-action">
      <el-button type="primary" @click="start" v-if="!run">启动待机，每分钟检测是否需要下单</el-button>
      <el-button danger @click="close" v-else>停止待机</el-button>
    </div>
  </div>

</template>


<script setup>
import {ref} from "vue";
import {Ajax, kDate, layer} from "@/utils";
import {AppConfig} from "@/app-config";

const fileUrl = AppConfig.openServer + '/u/buyerSwitch.json';
const run = ref(false);
const open = ref(false);
const logId = ref(1);
const lastUpdate = ref('');
const lastQuery = ref('');

getStatus();

function getStatus() {
  Ajax.get(fileUrl, {
    t: new Date().valueOf()
  }).then(res => {
    open.value = res.open;
    logId.value = res.logId;
    lastUpdate.value = res.lastUpdate;

    lastQuery.value = new kDate().toString();



    if (run.value) {
      if (open.value) {//如果检测到了提交订单，则自动关闭监听
        run.value = false;
        openJd();
      } else {
        setTimeout(function () {
          getStatus();
        }, 60000)
      }
    }
  });

}

function openJd() {
window.open('https://cart.jd.com/cart_index','jd')
}

function start() {
  run.value = true;
  getStatus();
}

function close() {
  run.value = false;
}
</script>

<style scoped lang="less">
.run {
  color: red;
}

.stop {
  color: green;
}

.p-action {
  margin-top: 50px;
}

.p-date {
  color: #0d84ff;
}

p {
  line-height: 32px;
  font-size: 16px;
}
</style>