<template>
  <iframe :src="url"></iframe>
</template>


<script setup>
import {ref} from "vue";

const skuList = [
  '100038350629',
  '100054388907',
  '100076482203',
  '100081967774'
];
const url = ref('');

let index = -1;

showNext();

function showNext() {
  index++;
  if (index >= skuList.length) {
    index = 0;
  }
  url.value = `https://item.jd.com/${skuList[index]}.html`;

  setTimeout(function () {
    showNext();
  }, 30000)
}

</script>

<style scoped lang="less">
iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
</style>