import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import index from '../views/index.vue'
import help_install from '../views/help/install.vue'
import help_config_open from '../views/help/config_open.vue'

import listen_jd from '../views/listen/jd.vue'

const routes= [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/help/install',
    name: 'help_install',
    component: help_install
  },
  {
    path: '/help/config_open',
    name: 'help_config_open',
    component: help_config_open
  },
  {
    path: '/listen/jd',
    name: 'listen_jd',
    component: listen_jd
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
