class LayerModel {

    constructor() {

    }

    /**
     * 显示模态窗口 需要点击确认才能进行下一步
     * @param content
     * @param title
     */
    alert(content, title = '提示') {
    }


    /**
     * 显示一个确认窗口 可选确认和取消
     * @param content
     * @param title
     */
    confirm(content, title = '操作确认') {
        return Promise.resolve();
    }


    /**
     * 显示一个警告窗口
     * @param msg
     */
    warning(msg) {
        return this.alert(msg);
    }

    /**
     * 展示错误信息
     * @param msg
     */
    error(msg) {
        this.toptip(msg, 'error');
    }

    /**
     * 展示成功信息
     * @param msg
     * @param time
     */
    success(msg, time = 1500) {
    }

    /**
     * 展示顶部提示信息
     * @param msg
     * @param type
     */
    toptip(msg, type = 'error') {
    }

    /**
     * 展示居中透明提示信息
     * @param msg
     * @param time - 自动关闭时间 单位毫秒
     */
    toast(msg, time = 1500) {
    }

    /**
     * 显示加载动画
     * @param title
     */
    loading(title = '正在加载') {
    }

    /**
     * 隐藏加载动画
     */
    hideLoading() {
    }

    /**
     * 展示提示信息 与alert只是默认的标题不同
     * @param content
     * @param title
     */
    notice(content, title = '温馨提示') {
        return this.alert(content, title);
    }


    onConfirm() {

    }

    onCancel() {

    }
}


const layer = new LayerModel();

export {
    layer,
    LayerModel
}
