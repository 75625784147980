<template>
  <div class="a-help">

    <div class="a-help-notice">在谷歌浏览器地址栏右边点击ReRes的图标，点击管理规则</div>
    <p><img :src="AppConfig.imgServer+'/images/reres_setup1.png'"/></p>
    <div class="a-help-notice">隐私和安全->安全</div>
    <p><img :src="AppConfig.imgServer+'/images/reres_setup2.png'"/></p>


    <div class="a-help-notice">当前有效配置</div>
    <el-form v-for="(item,index) in list" :key="index" label-width="100">
      <el-form-item label="If URL match">
        <el-input v-model="item.oldValue"></el-input>
      </el-form-item>
      <el-form-item label="Response">
        <el-input v-model="item.newValue"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>


<script setup>
import {common} from "@/utils";
import {AppConfig} from "@/app-config";


common.setTitle('浏览器及插件安装');

const list = [{
  oldValue: 'https://storage.jd.com/retail-mall/JDC_user_cart/1.6.19/js/Cart.cbbcb0a3.js',
  newValue: 'https://jd.feihe.site/cart.js'
}, {
  oldValue: 'https://trade.jd.com/static/js/lib/other/jquery.cookie.js',
  newValue: 'https://jd.feihe.site/jquery.cookie.js'
}];

</script>

<style scoped lang="less">
</style>