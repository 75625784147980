const contentType = {
    get: undefined,
    post: "application/x-www-form-urlencoded; charset=UTF-8",
    postJson: "application/json; charset=UTF-8",
    file: "multipart/form-data"
};
import {layer} from "@/utils";
import {AppConfig} from "@/app-config";
import Axios from "axios";
import qs from "qs";
import {Global} from "../global";

/*

 */
class AjaxModel {
    //header: {token:string}};
    //loadingNum: number;
    //_lock: boolean;

    constructor() {
        this.header = {
            token: ""
        }; //	该对象下的所有数据均会添加到Ajax请求头中。主要用于发送token
        this.loadingNum = 0; //正在进行中的启用了加载动画的Ajax请求数量
        this._lock = false;//是否锁定 用于防止重复提交
    }

    //region jsonp
    /**
     * 跨域调用接口
     * @param {string} url - 接口地址
     * @param {Object} data - 数据
     * @param {Function} callback - 回调函数
     */
    jsonp(url, data = {}, callback) {
        const script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        let callbackFun = `callback${Global.newId()}`;
        url = url + "?callback=" + callbackFun;
        Object.keys(data).forEach(function (key) {
            url += `&${key}=${data[key]}`;
        });
        script.src = url;
        document.body.appendChild(script);

        //添加一个全局函数 使用完以后自动清理
        window[callbackFun] = function (data) {
            callback(data);
            delete window[callbackFun];
        };
    }

    //endregion

    /**
     * 自动转化短路径为长路径
     * @param api
     */
    apiFilter(api = "") {
        if (api.indexOf("//") !== 0 && api.indexOf("/") === 0) {
            return AppConfig.apiUrl(api);
        }
        return api;
    }

    /**
     * 创建请求头
     * @param {"get" | "post" | "postJson" | "file"} type
     * @returns {{"X-Requested-With": string, "Content-Type": *}}
     */
    newHeader(type = "get") {
        let data = {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": contentType[type]
        };
        if (type === "get" && this.header.token) {
            data = {
                token: this.header.token
            };
        }
        Object.assign(data, this.header);
        return data;
    }

    //region 防止重复提交 whileUnlock、unlock
    /**
     * 用于防止重复提交
     * @returns {Promise<void>}
     */
    whileUnlock() {
        if (this._lock) {
            return Promise.reject();
        } else {
            this._lock = true;
            return Promise.resolve();
        }
    }

    /**
     * 解除锁定
     */
    unlock() {
        this._lock = false;
    }

    //endregion

    /**
     * 服务端出错时触发
     * @param err
     * @returns {Promise<void>}
     */
    onServerError(err) {
        console.log("err", err);
        return Promise.reject("服务器未响应，或接口地址有误");
    }

    /**
     * 出错时触发
     * @param {string} msg
     */
    onError(msg = "") {
        layer.error(msg);
    }

    //region 动画 loadingStart、loadingStop、onLoadingShow、onLoadingHide
    /**
     * 请求开始前触发
     */
    loadingStart() {
        //console.warn("请求开始");
        this.loadingNum++;
        this.onLoadingShow();
    }

    /**
     * 请求结束时触发
     */
    loadingStop() {
        //console.warn("请求结束");
        this.loadingNum--;
        if (this.loadingNum === 0) {
            this.onLoadingHide();
        }
    }

    /**
     * 需要显示加载动画时触发
     */
    onLoadingShow() {
        layer.loading();
    }

    /**
     * 需要停止加载动画时触发
     */
    onLoadingHide() {
        layer.hideLoading();
    }


    //endregion

    /**
     * 请求结束后触发
     * @param {object} result
     * @returns {Promise<any>}
     */
    afterRequest(result) {
        const res = this.filter(result);
        return new Promise((resolve, reject) => {
            if (res.success || res.code === 0 || res.code === 200) {
                resolve(res);
            } else {
                this.onError(res.msg);
                reject(res.msg);
            }
        });
    }

    /**
     * 请求结束后触发 用于对数据进行统一的预处理
     * @param result
     * @returns {*}
     */
    filter(result) {
        return result;
    }

    /**
     * 请求开始前触发 用于为所有请求添加统一的数据
     * @param data
     * @returns {*}
     */
    everyPara(data = {}) {
        return data;
    }

    /**
     *
     * @param {string} url
     * @param {Object} params
     * @param {"get" | "post" | "postJson" | "file"} type
     * @returns {Object}
     */
    createData(
        url,
        params = {},
        type = "get"
    ) {
        let postData = {
            url: this.apiFilter(url),
            timeout: 30000,
            headers: this.newHeader(type),
            method: type
        };

        switch (type) {
            case "get":
                postData.params = params;
                break;
            case "post":
                postData.data = qs.stringify(params);
                break;
            case "postJson":
                postData.data = params;
                postData.method = "post";
                break;
        }
        return postData;
    }

    /**
     * get方式提交数据
     * @param {string} url - 接口地址
     * @param {Object} data - 数据
     * @param {boolean} showLoading - 是否显示加载动画
     * @param {boolean} useFilter - 是否进行预处理
     * @returns {Promise}
     */
    get(
        url = "",
        data = {},
        showLoading = true,
        useFilter = true
    ) {
        url = url || AppConfig.defaultApi;
        this.everyPara(data);
        if (showLoading) {
            this.loadingStart();
        }
        if(AppConfig.debug){
            console.log('开始查询',url,data)
        }
        return Axios(this.createData(url, data, "get"))
            .then(res => {
                console.log(res.data)
                if (showLoading) {
                    this.loadingStop();
                }
                if (useFilter) {
                    return this.afterRequest(res.data);
                } else {
                    return Promise.resolve(res.data);
                }
            })
            .catch(res => {
                if (showLoading) {
                    this.loadingStop();
                }
                if (AppConfig.debug) {
                    layer.alert(url, "接口请求失败");
                }
            });
    }

    /**
     * post form方式提交数据
     * @param {string} url - 接口地址
     * @param {Object} data - 数据
     * @param {boolean} showLoading - 是否显示加载动画
     * @param {boolean} useFilter - 是否进行预处理
     * @returns {Promise}
     */
    post(
        url,
        data = {},
        showLoading = true,
        useFilter = true
    ) {
        url = url || AppConfig.defaultApi;

        this.everyPara(data);
        if (showLoading) {
            this.loadingStart();
        }

        if(AppConfig.debug){
            console.log('开始查询',url,data)
        }
        return Axios(this.createData(url, data, "post")).then(res => {
            console.log(res.data)
            if (showLoading) {
                this.loadingStop();
            }
            if (useFilter) {
                return this.afterRequest(res.data);
            } else {
                return Promise.resolve(res.data);
            }
        });
    }

    /**
     * post方式提交json数据
     * @param {string} url - 接口地址
     * @param {Object} data - 数据
     * @param {boolean} showLoading - 是否显示加载动画
     * @param {boolean} useFilter - 是否进行预处理
     * @returns {Promise}
     */
    postJson(
        url,
        data = {},
        showLoading = true,
        useFilter = true
    ) {
        url = url || AppConfig.defaultApi;
        this.everyPara(data);
        if (showLoading) {
            this.loadingStart();
        }
        return Axios(this.createData(url, data, "postJson")).then(res => {
            console.warn("接口请求结果", res.data);
            if (showLoading) {
                this.loadingStop();
            }
            if (useFilter) {
                return this.afterRequest(res.data);
            } else {
                return Promise.resolve(res.data);
            }
        });
    }

    /**
     * get方式获取文本数据
     * @param {string} url
     * @param paras
     * @param showLoading
     */
    getText(url, paras = {}, showLoading = true) {
        this.everyPara(paras);
        return this.get(url, paras, showLoading, false);
    }

    /**
     * 获取分页数据 默认为get方式
     * @param {string} url
     * @param {number} page
     * @param {Object} opt
     * @param {"get" | "post"} mode
     * @returns {PromiseLike<T | never> | Promise<T | never>}
     */
    getPage(
        url,
        page = 1,
        opt,
        mode = "get"
    ) {
        opt = Object.assign(
            {
                paras: {},
                limit: 20,
                /**
                 *
                 * @param {boolean} isEmpty
                 */
                onEnd: function (isEmpty) {
                },
                filter: function (result) {
                    return result;
                }
            },
            opt || {}
        );
        const paras = Object.assign(this.buildPagePara(page, opt.limit), opt.paras);

        return this[mode](url, paras).then(function (result) {
            if (!result) {
                opt.onEnd(page === 1);
                return {
                    total: 0,
                    data: []
                };
            }
            result = opt.filter(result);
            result.total = parseInt(result.total); //后台给出的可能是字符串
            result.data = result.data || [];
            if (result.data.length < opt.limit) {
                opt.onEnd(page === 1);
            }
            return result;
        });
    }

    /**
     * 构造分页参数
     * @param {number} page
     * @param {number} limit
     * @returns {{offset: number, limit: *}}
     */
    buildPagePara(page, limit) {
        return {
            limit,
            offset: (page - 1) * limit
        };
    }
}

export {AjaxModel};
