<template>

  <div v-for="(first,firstIndex) in list" :key="firstIndex" class="p-list">
    <div class="p-first-label" @click="toggle(first)">{{ first.label }}</div>
    <div class="p-second" :class="first.expand?'show':'hide'">
      <router-link :to="item.url" v-for="(item,index) in first.children" :key="index" class="c-item">{{
          item.label
        }}
      </router-link>

    </div>
  </div>
</template>


<script setup>
import {ref} from "vue";

const list = ref([
  {
    label: '客户端运行',
    children: [
      {
        label: '京东下单',
        url: '/'
      }
    ],
    expand: true
  },


  {
    label: '帮助文档',
    expand: true,
    children: [
      {
        label: '浏览器及插件安装',
        url: '/help/install'
      },
      {
        label: '启动时配置',
        url: '/help/config_open'
      },

      {
        label: '关闭时配置',
        url: '/help/config_close'
      }
    ]
  },
    /*
  {
    label: '价格监听',
    children: [
      {
        label: '京东',
        url: '/listen/jd'
      }
    ],
    expand: true
  },

     */

]);

function toggle(item) {
  item.expand = !item.expand;
}
</script>

<style scoped lang="less">
.p-first-label {
  background-color: #576B95;
  color: white;
  line-height: 50px;
  font-size: 20px;
  padding-left: 20px;

  &:hover {

    background-color: #cdd8ef;
    color: #576B95;
    animation: colorChange 1s;
  }
}

.p-second {
  padding: 10px 20px 10px 40px;
  background-color: white;
  overflow-y: hidden;

  &.show {
    height: auto;
  }

  &.hide {
    height: 0;
    padding: 0;
  }

  .c-item {
    display: block;
    line-height: 32px;
    font-size: 14px;
  }
}

@keyframes show {
  from {
    height: 0
  }
  to {
    height: 200px
  }
}

@keyframes hide {
  from {
    height: auto
  }
  to {
    height: 0
  }
}

@keyframes colorChange {
  0% {
    background-color: #576B95;
  }
  100% {
    background-color: #cdd8ef;
  }
}
</style>