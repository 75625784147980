import { getMaxDayOfMonth } from "./getMaxDayOfMonth";
import {
  getTimeDiffText,
  getTimeDiffSeconds,
  timeDiffToStr,
  getTimeDistance,
  getTimeDiffObj
} from "./timeDiff";
import { strToDate, parse, format, parseAndFormat } from "./parse";
import { getWeekText } from "./getWeekText";
import { CountDown } from "./countDown";
import { kDate } from "./kDate";

const dtTool = {
  getMaxDayOfMonth,
  getTimeDiffText,
  getTimeDiffSeconds,
  timeDiffToStr,
  getTimeDistance,
  getTimeDiffObj,
  strToDate,
  parse,
  format,
  parseAndFormat,
  getWeekText,
  CountDown
};
export default dtTool;
export { dtTool, kDate };
