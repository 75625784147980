<template>
  <div class="a-help">
    <div class="a-help-title">1.安装谷歌浏览器</div>
    <p><a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank">打开谷歌浏览器官网，https://www.google.cn/intl/zh-CN/chrome/，自行下载安装</a>
    </p>
    <div class="a-help-title">2.安装ReRes插件</div>
    <p><a href="https://jd.feihe.site/download/ReRes v1.8.1.zip" target="_blank">点击这里下载安装包，然后自行解压缩</a>
    </p>
    <p>（以上两个安装包都可以自行保存在本地电脑，然后在连接云服务器的时候直接复制粘贴）</p>

    <div class="a-help-notice">打开谷歌浏览器，点击右上角->设置</div>
    <p><img :src="AppConfig.imgServer+'/images/gg_config1.png'"/></p>
    <div class="a-help-notice">隐私和安全->安全</div>
    <p><img :src="AppConfig.imgServer+'/images/gg_config2.png'"/></p>
    <div class="a-help-notice">安全浏览 -> 不保护</div>
    <p><img :src="AppConfig.imgServer+'/images/gg_config3.png'"/></p>

    <div class="a-help-notice">再次点击浏览器右上角，扩展程序->管理扩展程序</div>
    <p><img :src="AppConfig.imgServer+'/images/install_1.png'"/></p>

    <div class="a-help-notice">勾选 开发者模式</div>
    <p><img :src="AppConfig.imgServer+'/images/install_2.png'"/></p>

    <div class="a-help-notice">把解压缩以后的ReRes v1.8.1.crx，直接拖到浏览器窗口这里</div>
    <div class="a-help-notice">插件安装成功以后，扩展程序显示如下，表示安装已经成功</div>
    <p><img :src="AppConfig.imgServer+'/images/install_3.png'"/></p>

    <div class="a-help-notice">在浏览器地址栏后面找到这个图标，打开以后，选择把ReRes固定在地址栏后面，方便后续进行管理</div>
    <p><img :src="AppConfig.imgServer+'/images/install_4.png'"/></p>
  </div>
</template>


<script setup>
import {common} from "@/utils";
import {AppConfig} from "@/app-config";


common.setTitle('浏览器及插件安装');

</script>

<style scoped lang="less">
img {
  max-width: 600px;
  height: auto;
}
</style>