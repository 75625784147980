/**
 *
 * @property {number} _time - 倒计时时间（秒）
 * @property {number} second - 倒计时剩余时间（秒）
 */
class CountDown {
    constructor(_time = 60) {
        this._time = _time;
        this.second = 0;
    }

    /**
     * 启动倒计时
     */
    start() {
        this.second = this._time;
        this._djs = setInterval(() => {
            this.second--;
            if (this.second === 0) {
                this.clear();
                this.onEnd();
            }
        }, 1000);
    }

    /**
     * 倒计时结束时触发的回调
     */
    onEnd() {
    }

    clear() {
        clearInterval(this._djs);
    }
}

export {CountDown};

export default CountDown;
